import React from 'react';
import { Container } from 'react-bootstrap';


// load the carbon_intensity.html in public

const UKElectricityGrid = () => {
    return (
<>
        <Container style={{ marginTop: '1rem' }}>
          <h1>UK Electricity Grid Carbon Intensity</h1>
          <hr />
          <p>
            The data is directly from National Grid ESO, and can be accessed via this{' '}
            <a href="https://data.nationalgrideso.com/carbon-intensity1/historic-generation-mix">link</a>. 
            I'd advise that this page is best viewed on a large screen, as the graphs are interactive
          </p>
          <h2>Generation Mix by month</h2>
          <hr />
          <p>
            The UK's electricity mix is decarbonising rapidly, but there are still stubborn periods of the day which tend to be fossil fuel heavy
            and this detail is totally lost in monthly generation plots.
            There is still a lack of information on how electricity carbon intensity varies throughout the day.
            Below is a typical example of a monthly plot, which shows only part of the decarbonisation story:
          </p>
          {/* Static HTML files can be displayed via an iframe or similar approach if necessary */}
          <Container fluid>  {/* Use fluid container for full-width iframe */}
        <iframe src="energy_mix.html" title="Energy Mix" style={{ width: '100%', height: '500px' }} />
      </Container>
          <div className="padding" />  {/* padding can be managed via CSS */}
          <p>
            It really is impressive how quickly coal is being phased out, and how quickly renewables are being added.
            There's still some information missing though, and that's how the generation mix varies throughout the day.
            The heatmaps below shed some light on this.
          </p>
          <Container fluid>  {/* Use fluid container for full-width iframe */}
          <iframe src="wind_heatmap.html" title="Wind Heatmap" style={{ width: '100%', height: '500px' }} />
        </Container>
          <div className="padding" />
          <p>
            The generation mix of the electricity grid in turn influences the carbon intensity of each electron powering our electric devices.
            Armed with the information contained in these plots, we can be informed about the impact 'Demand Shifting' has on our energy consumption's
            carbon intensity. <b> As a rule of thumb, if you can shift flexible loads (e.g. washing machines, dishwashers, electric vehicles) to times
            outside of the evening, you will reduce your carbon footprint.</b>
          </p>
          <Container fluid>  {/* Use fluid container for full-width iframe */}
          <iframe src="carbon_intensity_heatmap.html" title="Carbon Intensity Heatmap" style={{ width: '100%', height: '500px' }} />
          </Container>


          <h2>Carbon Intensity by month</h2>
          <p>
            The plot below shows the carbon intensity of the UK's electricity grid by month. This is important information to know, as it can help policy makers change
            legislation with respect to heating, and unfornately a lot of legislation is out of date: <a href="https://bregroup.com/documents/d/bre-group/s10tp-17_monthly_factors_for_co2_emissions_and_primary_energy_v1_0">here is what BRE think monthly factors are</a>
          </p>
          <Container fluid>  {/* Use fluid container for full-width iframe */}
          <iframe src="monthly_average_co2.html" title="Carbon Intensity by Month" style={{ width: '100%', height: '500px' }} />
          </Container>
    
        </Container>
        

        </>
      );
}

export default UKElectricityGrid;